
import './App.css';

import MusicFinder from './MusicFinder/MusicFinder';


function App() {
  return (
    
    <MusicFinder/>
    
  );
}

export default App;
