import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setFavouriteSongs } from './Redux/Logic_slice';




function PlayingInterFace(props) {


  let audioRef = useRef("")
  let [volume, setVolume] = useState(100)


  // Function for Add to favourites 
  let dispatch = useDispatch();
 


  // Adding to Favourites
  const DataToFavourites = (obj) =>{
    let favoriteSongs = localStorage.getItem('Favourite_Songs')
    if (favoriteSongs!==null) {
        let updateArray = JSON.parse(favoriteSongs)
        updateArray.push(obj)
        dispatch(setFavouriteSongs(updateArray))
        localStorage.setItem('Favourite_Songs',JSON.stringify(updateArray))
    }else{
      let NewArray = []
      NewArray.push(obj)
      dispatch(setFavouriteSongs(NewArray))
      localStorage.setItem('Favourite_Songs',JSON.stringify(NewArray))
    }
  }

  // Removing to Favourites
  const RemoveToFavourites = (obj) =>{
      let favoriteSongs = localStorage.getItem('Favourite_Songs')
      let NewOne = JSON.parse(favoriteSongs)
      localStorage.setItem('Favourite_Songs',JSON.stringify(NewOne.filter(song => song.trackId !== obj.trackId)))
      dispatch(setFavouriteSongs(JSON.parse(localStorage.getItem('Favourite_Songs'))))
    }


  // All States
  let [display, setDisplay] = useState(true)
  let [isPlaying, setPlaying] = useState(true)
  let [playIcon, setPlayIcon] = useState(`fas fa-play`)
  let [timeLine, settimeLine] = useState(0)
  let [showVolumeTrack, setVolumeTrack] = useState(false)
  let [currentTime, setCurrentTime] = useState(0)
  let [IsScreenSizeMaximum, setScreenSize] = useState(false) // for screen
  let [should_Screen_Minimize, setScreenMinimize] = useState(false)
  let [isThisFavourite, setThisFavourite] = useState(false)
  let [SongDuration , setSongDuration] = useState(false)
  let volumeBtnStyleWhenHidden = {
    width: '0px',
    visibility: 'hidden',
    transition: 'all .3s linear'
  }
  let volumeBtnStyleWhenVisible = {
    width: window.innerWidth <= 560 ? '60px' : '200px',
    visibility: 'visible',
    transition: 'all .3s linear'
  }




  // seconds to accurate Minutes 
  const ExactMinutes = (params) => {
    const totalSeconds = params;
    // 👇️ get the number of full minutes
    const minutes = Math.floor(totalSeconds / 60);
    // 👇️ get the remainder of the seconds
    const seconds = totalSeconds % 60;
    function padTo2Digits(num) {
      return num.toString().padStart(2, '0');
    }
    // ✅ format as MM:SS
    return `${padTo2Digits(minutes)}:${padTo2Digits(seconds)}`;
  }




  // Funtion for Music player timimg track

  function SetTrack(param) {
    if (param == true) {
      let TractTiming = setInterval(() => {
        let duration = Math.round(audioRef.current.duration)
        let currentTime = Math.round(audioRef.current.currentTime)
        if (currentTime == duration) {
          settimeLine(ExactMinutes(0))
          setCurrentTime(0)
          setPlayIcon(`fas fa-play`)
        } else {
          setCurrentTime(Math.round(audioRef.current.currentTime))
        }
      }, 1000);
    } else {

    }
  }




  // Pause & Play
  function Play(params) {
    if (audioRef.current.paused === true) {
      audioRef.current.play()
      SetTrack(true)
    }
    else {
      audioRef.current.pause();
      SetTrack(false)

    }
  }







  useEffect(() => {
    setPlayIcon('fas fa-play')
  }, [props])


 

  // UseEffect for setting passed time
  useEffect(() => {
    settimeLine(ExactMinutes(Math.round(audioRef.current.currentTime)))
    ExactMinutes(Math.round(audioRef.current.duration)) !== 'NaN:NaN' ? setSongDuration(true) : setSongDuration(false);
  }, [audioRef.current.currentTime])


  useEffect(()=>{
    let Favourite_Songs = localStorage.getItem('Favourite_Songs');
    if (Favourite_Songs !== null) {
      let Results = JSON.parse(localStorage.getItem('Favourite_Songs'));
      let isPresent = Results.some(song => song.trackId === props.SongData.SongID);
      if (isPresent) {
        setThisFavourite(true)
      }else{
        setThisFavourite(false)
      }
    }else{
      setThisFavourite(false)
    }
    
  },[props.SongData.SongObject])



  return (

    <div className={should_Screen_Minimize ? 'MusicInterface Minimize' : 'MusicInterface'} style={{ display: props.PlayState ? 'flex' : 'none', width: !IsScreenSizeMaximum ? '90vw' : should_Screen_Minimize ? '91vw' : '99vw' , height: !IsScreenSizeMaximum ? 'fit-content' : should_Screen_Minimize ? 'fit-content' : '97vh' }}>
      <audio src={props.SongData.SongSource} controls ref={audioRef} style={{ display: 'none' }}></audio>
      <div className="header">
        <button id='minimize' onClick={() => {
          setScreenMinimize(should_Screen_Minimize ? false : true)
        }} ><i className={should_Screen_Minimize ? 'fas fa-angle-up' : 'fas fa-angle-down'}></i></button>


        <button id='close' onClick={() => {
          audioRef.current.pause();
          props.CloseMusicPlayer(false)
          setPlayIcon(`fas fa-play`)
          audioRef.current.currentTime = 0
        }} ><i className='fas fa-times'></i></button>
      </div>



      <div className="body">
        <div className="logo">
          <h2>My<span> Music</span> App</h2>
        </div>

        <div className="SongDetails">
          <div className="volume">
            <h3><span>Artist</span> : {props.SongData.ArtistName ? props.SongData.ArtistName : 'Not Given'}</h3>
            <h3><span>Song</span> : {props.SongData.SongName ? props.SongData.SongName : 'Not Given'}</h3>
          </div>


        </div>

      </div>

      <div className='footer'>

        {/* Track */}
        <div className="track">

          {/* Calling ExactMinutes fucntion */}
          <p className="time">{timeLine}</p>


          {/* Track */}
          <input type="range" value={currentTime} className='rs-range' min='0' max={audioRef.current.duration !== NaN ? `${audioRef.current.duration}` : '30'} onChange={(e) => {
            settimeLine(Math.round(audioRef.current.currentTime))
            setCurrentTime(Math.round(audioRef.current.currentTime))
            audioRef.current.currentTime = e.target.value
          }} />


          {/* Duration or total minutes */}
          <p className="time">{
           SongDuration ?  ExactMinutes(Math.round(audioRef.current.duration)) !== 'NaN:NaN' ? ExactMinutes(Math.round(audioRef.current.duration)) : '00:30' : '00:00'
          }</p>
        </div>




        <div className="controls">

          <div className="start">
            {/* Adding songs to favourites */}
            <button style={{color: isThisFavourite ? '#ef0660' : '#fff'}} onClick={() => {
              if (!isThisFavourite) {
                DataToFavourites(props.SongData.SongObject)
                setThisFavourite(true)
              }else{
                RemoveToFavourites(props.SongData.SongObject)
                setThisFavourite(false)
              }
            }}><i className='fas fa-heart'></i></button>
          </div>
          <div className="middle">
            {/* Download */}
            <div className="dowloadBtn">
              <button><a href={props.SongData.SongSource} download><i className='fas fa-download'></i></a></button>
            </div>


            {/* Play/pause Btn */}
            <div className="playBtn" onClick={() => {
              setPlayIcon(playIcon === 'fas fa-play' ? 'fas fa-pause' : 'fas fa-play')
              Play();
            }}>
              <i className={playIcon}></i>
            </div>


            {/* Volume*/}
            <div className="volumeBtn vertical-slider">
              <button onMouseEnter={() => setVolumeTrack(true)} onMouseLeave={() => setVolumeTrack(false)}><i className='fas fa-volume-up'></i></button>


              <input onClick={() => setVolumeTrack(true)} onMouseEnter={() => setVolumeTrack(true)} onMouseLeave={() => setVolumeTrack(false)} type="range" value={volume} style={!showVolumeTrack ? volumeBtnStyleWhenHidden : volumeBtnStyleWhenVisible} onChange={(e) => {
                setVolume(e.target.value)
                audioRef.current.volume = volume / 100;
                setVolume(e.target.value)
              }} />
            </div>
          </div>


          <div className="end">
            <button onClick={() => IsScreenSizeMaximum === false ? setScreenSize(true) : setScreenSize(false)}><i className={IsScreenSizeMaximum ? 'fas fa-minimize' : 'fas fa-expand'}></i></button>
          </div>


        </div>
      </div>
    </div>

  )
}

export default PlayingInterFace
