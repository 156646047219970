import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import MySpinner from './LoadingSpinner';
import { setRecentSongs } from './Redux/Logic_slice';


function AllSongs(props) {


    let [data, setData] = useState(false);
    let [demandSearch , setSearch] = useState('Lawrence Oyor')
    let [clickSearch,setCS] = useState('Lawrence Oyor')



    useEffect(() => { // Fatching API's data
      let timer = setTimeout(() => {
      props.setLoadingProgress(30) 
      let url = `https://itunes.apple.com/search?term=${demandSearch.replace(" ","_")}&limit=50&media=music`;
      fetch(url).then((songs)=>{
        props.setLoadingProgress(50) 
        return songs.json()
      }).then((results)=>{
        props.setLoadingProgress(100) 
        setData(results.results)
      }).catch((eror)=>{
        props.setLoadingProgress(100) 
        console.log('Error has occured while fetching...');
      })
      console.log('Called');
      }, 800);
      return () => clearTimeout(timer);
    },[demandSearch])



    // Sending data to store for recent-played-section



    // Getting old data to update it on after new song play
    let songArray = useSelector((data)=>data.songData.RecentSongsData)
    // creating a dispatch function.
    let dispatch = useDispatch();

    // data to store
    const dataToStore = (song) =>{
        if (songArray.length == 0) {
          dispatch(setRecentSongs([song,...songArray]))
        }
        else if (songArray.length >= 4){
          dispatch(setRecentSongs([song,...songArray].splice(0,4)))
        }
        else {
          dispatch(setRecentSongs([song,...songArray]))
        }
    }

    


    // Data to local storage 
    const dataToRecentPlayedArray = (obj) =>{
      let recentPlayedArray = localStorage.getItem('recently_Played_Songs');
      if (recentPlayedArray !== null) {
        if (JSON.parse(recentPlayedArray).length  > 3) {
          let updateArray = JSON.parse(recentPlayedArray);
          let IsPlayedRecently = updateArray.some(song => song.trackId === obj.trackId)
          if (!IsPlayedRecently) {
            updateArray.pop();
            updateArray.unshift(obj)
            localStorage.setItem('recently_Played_Songs',JSON.stringify(updateArray))
          }
          
          
        }else{
          let updateArray = JSON.parse(recentPlayedArray);
          let IsPlayedRecently = updateArray.some(song => song.trackId === obj.trackId)
          if (!IsPlayedRecently) {
            updateArray.unshift(obj)
            localStorage.setItem('recently_Played_Songs',JSON.stringify(updateArray))
          }
        }
      }else{
        let NewArray = []
        NewArray.unshift(obj)
        localStorage.setItem('recently_Played_Songs',JSON.stringify(NewArray))
      }
    }

 
  return (
    <>
    <div className={props.setClassDueToMode === true ? 'search_field DarkSongSection' : 'search_field'}>
    <input type="text" placeholder='Find your Music' value={demandSearch} onChange={(e)=> setSearch(e.target.value)}/>
    <button style={{backgroundColor: props.setClassDueToMode ? '#282c34' : '#0bb490'}}> <i className='fas fa-search'></i></button>
   </div>
    <div className={props.setClassDueToMode === true ? 'songs DarkSongSection' : 'songs'} style={{height: data ? 'fit-content' : '71vh'}}>
        {
        data.length > 0 ? data.map((song,index)=>{
          
          return <div key={index} className={props.setClassDueToMode === true ? 'card DarkCards' : 'card'}> 
          <img src={song.artworkUrl100} alt="Not" />
          <div className="playIt">
              <h3>{`${song.artistName}`.slice(0,40) + " ..."}</h3>
              <button onClick={
              ()=>{
                dataToStore(song);
                dataToRecentPlayedArray(song)
                props.getAnswer(true)
                props.getDetailsOfSong(song.trackName,song.artistName,song,song.previewUrl,song.trackId)
              }
               } style={{backgroundColor: props.setClassDueToMode ?'rgb(149 146 146)' : '#0bb490'}}>PLAY <i className='fas fa-play'></i></button>
          </div>
        </div>
        })  : ( 
        <div className='notFoundingMsg'>
         <div className="icon">
         <i className='fas fa-music'></i>
         </div>
         <h2><span>Oops!</span> Can't find that song.</h2>
         <MySpinner className={'MyspinnerLoader'}/>

        </div>
        )
        }
        
    </div>
    </>
  )
}
export default AllSongs
