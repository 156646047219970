import React from 'react'
import { useState } from 'react';


function Navbar(props) {



  return (
    <>

      <nav className={props.setClassDueToMode === true ? 'musicNav DarkComponents' : 'musicNav'}>
        <div className="logo">
          <label> <a href='https://apimusic.sosamson.com'>Sosamson<span>Clips</span></a> </label>
        </div>
        <div className="icon" onClick={() => props.showHide(true)}>
          <i className='fas fa-bars'></i>
        </div>
      </nav>

    </>
  )
}

export default Navbar
