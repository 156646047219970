import React from 'react'
import { Link } from 'react-router-dom'

function SideBar(props) {
  return (
    <div className={props.setClassDueToMode ? 'Sidebar DarkSideBar' : 'Sidebar'} style={{right: props.visible === true ? '0%': '-200%'}}>

        <div className='closeIcon' onClick={()=>props.showHide(false)}>
        <i className='fas fa-times'></i>
        </div>

      <div className="musicPic">
        <i className='fas fa-music'></i>
      </div>
      <div className="menus">
        <li><Link to='music'><i className='fas fa-music'></i> Musics</Link></li>
        <li><Link to='/recent-played'><i className='fas fa-history'></i> Recent Played</Link></li>


        {/* Button for dark Mode */}
        <li onClick={()=>{
          props.mode === false ? props.shouldDarkModeEnable(true) : props.shouldDarkModeEnable(false) // when ever some one Click on this li The Dark will be Enabled!
        }}><i className={!props.mode ? 'fas fa-moon' : 'fas fa-sun'}></i>{!props.mode ? 'Dark Mode' : 'Light Mode'}</li>

        <li><Link to='/favourites'><i className='fas fa-heart'></i> Favourites</Link></li>
        <li><Link to='/about'><i className='fas fa-user'></i> Developer</Link></li>
      </div>
    </div>
  )
}

export default SideBar
