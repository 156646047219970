import React from 'react'
import { useState } from 'react'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { setFavouriteSongs, setRecentSongs } from './Redux/Logic_slice';


function Favourites_Songs(props) {

  // State for Redux Data
  let [ LocalStorageDataArray , updateLocalStorageDataArray] = useState(null)

  let Fav_Song_Array = useSelector((data) => data.songData.Favourites_Songs_Data);
  let dispatch = useDispatch();

  // UseEffect will re-render data whenever New one song will be added or removed
  useEffect(()=>{
    let getItems = localStorage.getItem('Favourite_Songs')
    if (getItems !== null) {
      updateLocalStorageDataArray(JSON.parse(localStorage.getItem('Favourite_Songs')))
      dispatch(setFavouriteSongs(JSON.parse(localStorage.getItem('Favourite_Songs'))))
    }else{
      dispatch(setFavouriteSongs(null))
    }
  },[localStorage])

  const RemoveToFavourite = (trackId) =>{
    let getItems = JSON.parse(localStorage.getItem('Favourite_Songs'))
    localStorage.setItem('Favourite_Songs',JSON.stringify(getItems.filter(song=>song.trackId !== trackId)))
    dispatch(setFavouriteSongs(getItems.filter(song=>song.trackId !== trackId)))
  }

  
  return (
   
    <div className={props.setClassDueToMode ? 'Favourites_Songs_Section DarkSongSection' : 'Favourites_Songs_Section'}>
        <h2>All Your <span>Favourites</span> <i className='fas fa-heart'></i> are here <span>!!!</span></h2>
        <div className="favs_songs_list">

        {
          localStorage.getItem('Favourite_Songs') !== null && Fav_Song_Array.length !== 0 ? Fav_Song_Array.map((favoriteSong,index)=>{
            return <div className={props.setClassDueToMode ? 'fav_songs_card DarkCards' : 'fav_songs_card'} key={favoriteSong.trackId}>
            <img src={favoriteSong.artworkUrl100} />
            <h3>{`${favoriteSong.trackName}`.length <= 15 ? `${favoriteSong.trackName}` : `${favoriteSong.trackName}`.slice(0,15) + '...'}</h3>
            <div className="threeBtns">
              <button title='Remove from Favourites' onClick={()=>{
                RemoveToFavourite(favoriteSong.trackId)
              }}><i className='fas fa-trash-alt'></i> Remove</button>
              <a href={favoriteSong.previewUrl} download title='Download this Song'><i className='fas fa-download'></i></a>
              <button title='Play' onClick={()=>{
                 props.getAnswer(true)
                 props.getDetailsOfSong(favoriteSong.trackName,favoriteSong.artistName,favoriteSong,favoriteSong.previewUrl,favoriteSong.trackId)
              }}><i className='fas fa-play-circle'></i></button>
            </div>
        </div>
            
          
          }) :  <> 
         
          <div className='notFoundingMsg'>
         <div className="icon">
         <i className='fas fa-hand-holding-heart'></i>
         </div>
         <h2>Nothing is Favourite ! </h2>
        </div>
           </>
        }
         
        </div>
    </div>
  )
}

export default Favourites_Songs
