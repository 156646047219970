import React from 'react'

function AboutPage(props) {
  return (
    <div className={props.setClassDueToMode === true ? 'AboutPage DarkSongSection' : 'AboutPage'}>
     
     <div>
     <h1>Welcome to <span>SosamsonClips Web Application</span></h1>
    <p>Our music clips provider web application offers a vast selection of high-quality music clips from various genres for users to enjoy. Our platform is designed to provide an intuitive and seamless user experience, with a simple and easy-to-use interface that allows users to browse, search and listen to their favorite music clips. We regularly update our collection to keep our users up to date with the latest releases and popular music. Whether you're a fan of rock, pop, hip-hop, or any other genre, our web application has something for everyone.</p>
     </div>
      <div>
      <h1>About this <span>Application</span></h1>
      <p>
        <li>Our music clips provider web application is built using HTML, CSS, JavaScript, and React, and it is fully responsive, ensuring that users can access and enjoy their favorite music clips from any device, including desktops, laptops, tablets, and smartphones. The application is designed to provide a seamless and intuitive user experience, with a simple and easy-to-use interface that allows users to browse, search, and listen to their favorite music clips.</li>
        <li>One of the key features of our application is that it uses APIs to provide users with access to a vast selection of high-quality music clips from various genres, ensuring that users can easily find and listen to the music they love.</li>
        <li>In addition to our extensive collection of music clips, our web application also includes both light and dark modes, allowing users to customize their viewing experience to match their preferences. This feature is especially useful for users who may prefer a darker interface for viewing their music clips, or those who may be sensitive to bright light.</li>
        <li>Overall, our music clips provider web application is a great tool for anyone who loves music and wants easy access to a vast selection of music clips from various genres. With its responsive design, API integration, and customizable interface, our web application is a valuable resource for music lovers everywhere.</li>
      </p>
      </div>
      
      <div>
      <h1>About <span>Me</span></h1>
      <p>
        <li>I am a passionate front-end development expert with two years of experience. I have a deep interest in creating intuitive and engaging user interfaces that deliver an exceptional user experience. I specialize in front-end development and have a strong background in HTML, CSS, JavaScript, and React.</li>
        <li>One of my key strengths is my ability to solve complex problems. I am skilled at analyzing difficult problems and identifying creative solutions that meet the needs of users. I am a natural problem solver and enjoy tackling challenging projects that require me to push the boundaries of my expertise</li>
        <li>Overall, I am a skilled and experienced front-end development expert who is passionate about creating intuitive and engaging user interfaces that deliver an exceptional user experience. My problem-solving skills and communication abilities make me a valuable asset to any development team.</li>
      </p>
      </div>
    </div>
  )
}

export default AboutPage
