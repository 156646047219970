import React from 'react'

import { Link } from 'react-router-dom'
import boyWhitePicture from './bay.PNG'

function Picture_Text(props) {



  return (
    <div className={props.setClassDueToMode === true ? 'Music_App_Initial DarkComponents' : 'Music_App_Initial'}>
        <div className='text_Para'>
            <h2>Find your <span>favourite Music</span></h2>
            <p>Welcome to <span>SosamsonClips Web Application</span>. Our music clips provider web application offers a vast selection of high-quality music clips from various genres for users to enjoy. Our platform is designed to provide an intuitive and seamless user experience, with a simple and easy-to-use interface that allows users to browse, search and listen to their favorite music clips.</p>
            <Link to='/music'>Let's Find</Link>
    
        </div>
        <div className='Image'>
            <img src={props.setClassDueToMode === true ? "darkBay.PNG" : boyWhitePicture} alt="Not_founding" />
        </div>
    </div>
  )
}

export default Picture_Text
