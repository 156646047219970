import React from 'react'
import { useState } from 'react';
import { useEffect } from 'react';
// import '../App.css';
import './AppStyling.css';
import './Media_Queries.css'

import AllSongs from './AllSongs';
import Navbar from './Navbar';
import Picture_Text from './Picture_Text';

import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link
} from "react-router-dom";
import SideBar from './SideBar';
import PlayingInterFace from './PlayingInterFace';

import LoadingBar from 'react-top-loading-bar';
import AboutPage from './AboutPage';
import RecentPlayedSongs from './RecentPlayedSongs';
import Favourites_Songs from './Favourites_Songs';







function MusicFinder() {
  // Whenever user click on play button This Answer will be true & Music Player will be visible
  let [shouldMusicPlayAnswer, setShouldMusicPlay] = useState(false)

  // State for show & hide sidebar
  let [show, setShow] = useState(false)

  // State for setting top-loading-bar's progression
  let [progress, setProgress] = useState(10)

  let [Song_Entire_Details_Object , set_Entire_Details_Object] = useState({
    SongName: null,
    ArtistName: null,
    SongSource: null,
    SongObject: null,
    SongID: null
  })

  // State to set Loading bar
  let [loading , setLoading] = useState(false)





  // Function to show & hide the SideMenu
  const showHide = (e) => {
    setShow(e)
  }

  // This function will be called whenever user click on play button in Songs Component
  // This will take a boolean (true , false) as a parameter & will set answer according to that!
  const shouldMusicPlay = (answer) => {
    return setShouldMusicPlay(answer)
  }

 

  // Function set the top loading bar
  const setLoadingProgress = (progress) =>{
    setProgress(progress)
  }


   // States to Get DarkMode & LightMode
   let [mode , setMode] = useState(false)


  // Function to Enable & disable Dark Mode
  const shouldDarkModeEnable = (toggler) =>{
     return setMode(toggler)
  }

  // function to get Song Name , Artist Name , Song Object
  const getDetailsOfSong = (SongName,ArtistName,SongObject,SongSource,SongID) =>{
    set_Entire_Details_Object({
      SongName,
      ArtistName,
      SongObject,
      SongSource,
      SongID 
    })
  }


  // function to set LoadingSpinner 
  const isLoading = (loading) =>{
    return setLoading(loading)
  }

  return (
    <>
      <Router>
        <Navbar showHide={showHide} setClassDueToMode={mode} />
        <SideBar visible={show} showHide={showHide} mode={mode} shouldDarkModeEnable={shouldDarkModeEnable} setClassDueToMode={mode} />
      
        <LoadingBar
          color='#0bb490'
          shadow='2px 2px 8px 1px #29f0c5'
          progress={progress}
          height={4}
          onLoaderFinished={() => setProgress(0)}
        />

       <PlayingInterFace SongData={Song_Entire_Details_Object} PlayState={shouldMusicPlayAnswer} CloseMusicPlayer={shouldMusicPlay} />

        <Routes>
          <Route exact path='/home' element={<Picture_Text setClassDueToMode={mode}/>}></Route>
          <Route path='/music' element={<AllSongs getLoadingStatus={isLoading} getDetailsOfSong={getDetailsOfSong} setClassDueToMode={mode} getAnswer={shouldMusicPlay} setLoadingProgress={setLoadingProgress} />}></Route>
          <Route path='/about' element={<AboutPage setClassDueToMode={mode}/>}></Route>
          <Route path='/recent-played' element={<RecentPlayedSongs setClassDueToMode={mode} getAnswer={shouldMusicPlay} getDetailsOfSong={getDetailsOfSong}/>}></Route>
          <Route path='/favourites' element={<Favourites_Songs setClassDueToMode={mode} getDetailsOfSong={getDetailsOfSong} getAnswer={shouldMusicPlay}/>}></Route>
          <Route path='*' element={<Picture_Text setClassDueToMode={mode}/>}></Route>
        </Routes>
      </Router>
    </>
  )
}
// #02aab0 → #00cdac
export default MusicFinder
