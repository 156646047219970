import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import NotDataPNG from './background_Recent_played.jpg'
import NotDataPNG_Black from './background_Recent_played_Black.png'


function RecentPlayedSongs(props) {

    let getStoreData = useSelector((data) => data.songData.RecentSongsData)
    let [StoreDataToArray , updateArray] = useState(getStoreData)
    let [hightOfSection , setHightOfSection] = useState({height:'91vh'})
    useEffect(()=>{
        updateArray(getStoreData)
    },[getStoreData])


  return (
        <div style={{hightOfSection}} className={props.setClassDueToMode === true ? 'recent-played-section DarkSongSection' : 'recent-played-section'}>
            <h2>Recent Played <span>Songs</span></h2>
            {
                JSON.parse(localStorage.getItem('recently_Played_Songs')) === null ? <div className='Recent_Played_Msg'><img src={props.setClassDueToMode === true ? NotDataPNG_Black : NotDataPNG} alt="" /></div>
                : JSON.parse(localStorage.getItem('recently_Played_Songs')).map((song,index)=>{
                    return  <div key={index} className={props.setClassDueToMode === true ? 'plainCard DarkCards' : 'plainCard'}>
                    <img src={song.artworkUrl100} alt="" />
                    <div className='name-details'>
                        <div>Name : {`${song.trackName}`.slice(0,5)+'...'}</div>
                        <div>Artist : {`${song.artistName}`.slice(0,5)+'...'}</div>
                    </div>
                    <div className='recent-controls'>
                        <button className={props.setClassDueToMode === true ? 'DarkButton' : ""}><i className='fas fa-play' onClick={()=>{
                             props.getAnswer(true)
                             props.getDetailsOfSong(song.trackName,song.artistName,song,song.previewUrl,song.trackId)
                        }}></i></button>
                    </div>
                </div>
                })
            }
        </div>
    )
}

export default RecentPlayedSongs
