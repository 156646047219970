import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    RecentSongsData: [],
    Favourites_Songs_Data: []
}

export const slice = createSlice({
    name: "SongData",
    initialState , 
    reducers : {
        setRecentSongs: (state , action)=>{
            state.RecentSongsData = action.payload;
        },
        setFavouriteSongs: (state, action)=>{
            state.Favourites_Songs_Data = action.payload
        }
    }
})

export const {setRecentSongs,setFavouriteSongs} = slice.actions

export default slice.reducer;